import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Dashboard.css';
import axios from "axios";

import Header from '../../Headers/Dashboard/Header';

import StripeCheckout from 'react-stripe-checkout';

export default function Dashboard({ AppState }) {
    const { host, userID, setUserID, userInfo, setUserInfo, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    const [showProScreen, setShowProScreen] = useState(false);
    const [email, setEmail] = useState('');
    const [databases, setDatabases] = useState([]);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));

            axios
                .get(`${host}/api/user/${getCookie('user_id')}`)
                .then(response => {
                    const data = response.data;
                    setUserInfo(response.data);
                    // console.log(data);

                    setEmail(response.data.email);

                    if(data.data_row_count < 4) {
                        setShowProScreen(false)
                    } else if(!data.paid_sub) {
                        setShowProScreen(true);
                    } else if(data.paid_sub && !data.custom_pricing) {
                        setShowProScreen(false)
                    }

                    if(data.number_of_databases_in_account !== null && data.number_of_databases_in_account > 0) {
                        axios
                            .get(`${host}/api/all/databases/${getCookie('user_id')}`)
                            .then(res => {
                                // console.log("res.data: ", res.data);
                                setDatabases(res.data);
                            })
                            .catch(err => console.log(err))  
                    }
                })
                .catch(err => console.log(err))
 
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    const createDatabase = async () => {
        let database = await axios   
                    .post(`${host}/api/database`, { userID })
                    .then(res => {
                        document.cookie = `database_id = ${res.data.database_id}; domain=fingodb.com; max-age=31536000;`;
                        document.cookie = `database_id = ${res.data.database_id}; max-age=31536000;`;
                        setDatabaseInfo(res.data);
                    })
                    .catch(err => console.log(err));
        console.log("database: ", database);

        navigate('/in-database-create', { replace: true });    
    }

    const openDatabase = async (databaseID) => {
        let database = await axios   
            .get(`${host}/api/database/${userID}/${databaseID}`, { userID })
            .then(res => {
                document.cookie = `database_id = ${res.data.database_id}; domain=fingodb.com; max-age=31536000;`;
                document.cookie = `database_id = ${res.data.database_id}; max-age=31536000;`;
                setDatabaseInfo(res.data);
            })
            .catch(err => console.log(err));
        console.log("database: ", database);

        navigate('/in-database-create', { replace: true }); 
    }

    const onToken = (token) => {
        // setShowLoading(true);

        axios
            .post(`${host}/api/stripe/token`, { userID, token, dataRowCount: userInfo.data_row_count, databaseCount: userInfo.number_of_databases_in_account })
            .then(res => {
                // console.log(res.data);
                setShowProScreen(false);
                // setShowLoading(false);
            })
            .catch(err => {
                console.log(err);
                alert("That card number didn't work. Please try again.");
                // setShowLoading(false);
            })  
    }

    return (
        <div className="dash-page">
            <Header AppState={AppState} />
            {showProScreen
                ? <div className="dash-body">
                    <h3>You have saved more then 3 rows of data. You must "Go Pro" to continue using the server.</h3>
                    <p>The price of Pro is $25/month per 1,000,000 rows of data saved. Click on the "Go Pro" button below to provide your card information to continue using FingoDB:</p>
                    <StripeCheckout
                        image={require('../../assets/logo.png')}
                        name="FingoDB Pro"
                        panelLabel="Start Membership"
                        email={email}
                        
                        currency='USD'
                        locale='auto'
                        stripeKey='pk_live_9o7xarrQQSR8yfoIwh5r2uIk00yFNr81db'
                        token={onToken}
                    >
                        <p className="dash-go-pro-button mb-0">Go Pro</p>
                    </StripeCheckout>

                    <h4 className="mt-5 mb-4">Frequently Asked Questions</h4>
                    <h5 className="">How does pricing work?</h5>
                    <p>Pricing is based on the number of total database rows posted to your databases. Here's how pricing works:</p>
                    <ul>
                        <li>3 database rows are free.</li>
                        <li>Then it's $25 per month per 1,000,000 rows of data.</li>
                    </ul>

                    <h5 className="mt-4">Can I cancel any time?</h5>
                    <p>Yes, there are no contracts. You can cancel any time by clicking the settings gear icon at the top of the screen and clicking "Cancel Membership".</p>

                    <h5 className="mt-4">Will my card be charged before I reach 4 rows of data?</h5>
                    <p>No. Your card will NOT be charged until you reach 4 rows of data. FindoDB is free up to 3 rows of data.</p>

                    <h5 className="pt-5 mt-5">If you have any questions, email us at: tj@fingobox.com</h5>
                  </div>
                : <div className="dash-body">
                    <div className="dash-create-button" onClick={() => createDatabase()}>
                        <p className="dash-white-text mb-0">+ &nbsp;&nbsp;Create a Database</p>
                    </div>
                    <div className="dash-apps-cont d-flex flex-wrap">
                        {databases.map((e, i) => {
                            return (
                                <div className="dash-app-cont" key={i} onClick={() => openDatabase(e.database_id)}>
                                    <p className="mb-0">{e.database_name}</p>
                                </div>
                            )
                        })}
                    </div>
                  </div>
            }
            
        </div>
    )
}