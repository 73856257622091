import React, { useState, useEffect } from "react";
import AppNavigation from '../AppNavigation/AppNavigation';

export default function AppState() {
    // dev
    // const host = 'http://localhost:4141';

    // live
    const host = 'https://fingodb.com';

    const [userID, setUserID] = useState(0);
    const [userInfo, setUserInfo] = useState(0);
    const [databaseInfo, setDatabaseInfo] = useState({});

    const AppState = {
        host,
        userID, setUserID,
        userInfo, setUserInfo,
        databaseInfo, setDatabaseInfo
    }

    return <AppNavigation AppState={AppState} />;
}
