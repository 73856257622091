import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Notes.css';
import axios from "axios";

import Header from '../../../../Headers/InDatabase/Header';
import SideBar from "../Components/SideBar/SideBar";

export default function Notes({ AppState }) {
    const { host, userID, setUserID, databaseInfo, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    const [notes, setNotes] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if (getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));

            axios
                .get(`${host}/api/database/${getCookie('user_id')}/${getCookie('database_id')}`, { userID })
                .then(res => {
                    // console.log("res.data: ", res.data, res.data.notes[0]);
                    setDatabaseInfo(res.data);

                    if (res.data.notes) {
                        setNotes(res.data.notes[0]);
                    }

                })
                .catch(err => console.log(err));
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    const handleChange = (event) => {
        // auto save event.target.value
        axios.post(`${host}/api/database/database/notes`, {
            userID,
            databaseID: databaseInfo.database_id,
            databaseNotes: event.target.value
        })
    }

    return (
        <div className="ia-notes-page">
            <Header AppState={AppState} />
            <div className="ia-notes-body">
                <SideBar AppState={AppState} />
                <div className="ia-ce-body-right">
                    <h3 className="ia-ce-header-text-30">Create notes about your database tables:</h3>
                    <textarea
                        className="h-100 m-4 p-4"
                        defaultValue={notes}
                        onChange={handleChange} />
                </div>
            </div>
        </div>
    )
}