import { useNavigate } from "react-router-dom";
import './Header.css';

export default function Header({ AppState }) {
    const { setUserID } = AppState;
    let navigate = useNavigate();

    const logout = () => {
        setUserID(0);
        var d = new Date();
        d.setDate(d.getDate() - 1);
        document.cookie = `user_id = 0; domain=fingodb.com; expires=${d};`;
        document.cookie = `user_id = 0; expires=${d};`;
        document.cookie = `logged_in = false; domain=fingodb.com; expires=${d};`;
        document.cookie = `logged_in = false; expires=${d};`;
        document.cookie = `database_id = 0; domain=fingodb.com; expires=${d};`;
        document.cookie = `database_id = 0; expires=${d};`;
        navigate('/', { replace: true });
    }

    return (
        <header className='dash-header'>
            <a className='d-flex align-items-center' href='/#/dashboard'>
                <img className='dash-logo' src={require('../../assets/logo.png')} alt='fingodb logo' />
                {/* <h1 className='dash-logo-text mb-0'>FingoDB</h1> */}
            </a>
            <div className="d-flex align-items-center">
                <div className="dash-head-settings-button" onClick={() => navigate('/settings', { replace: true })}>
                    <p className="mb-0 dash-head-gear-icon">&#x2699;</p>
                </div>
                <div className="dash-logout-button" onClick={() => logout()}>
                    <p className="dash-logout-text mb-0">Logout</p>
                </div>
            </div>
        </header>
    )
}