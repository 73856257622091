import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './APIs.css';
import axios from "axios";

import Header from '../../../../Headers/InDatabase/Header';
import SideBar from "../Components/SideBar/SideBar";

export default function Search({ AppState }) {
    const { host, userID, setUserID, databaseInfo, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    const [databaseTables, setDatabaseTables] = useState([]);
    const [showAPI, setShowAPI] = useState([]);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        if (getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));

            // get database info
            axios
                .get(`${host}/api/database/${getCookie('user_id')}/${getCookie('database_id')}`)
                .then(res => {
                    setDatabaseInfo(res.data);
                    getAllDatabaseTables();
                })
                .catch(err => console.log(err));
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    const getAllDatabaseTables = () => {
        // get all databaseTables
        axios
            .get(`${host}/api/all/database/tables/${getCookie('user_id')}/${getCookie('database_id')}`)
            .then(res => {
                setDatabaseTables(res.data);
                res.data.forEach(() => setShowAPI(element => [...element, false]));
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="ia-ce-page">
            <Header AppState={AppState} />
            <div className="ia-ce-body">
                <SideBar AppState={AppState} />
                <div className="ia-ce-body-right">
                    <h3 className="ia-ce-header-text-30">Database Table APIs:</h3>
                    <div className="d-flex flex-wrap pb-5">
                        {/* map all databaseTables */}
                        {databaseTables.map((e, i) => {
                            let columns = [];

                            if(e.table_columns) columns = Object.entries(e.table_columns);

                            return (
                                <div className="ia-ce-input-edit-cont" key={i}>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <h3 className="ia-ce-header-text mr-30">{e.table_name ? e.table_name : 'undefined'}</h3>
                                        <div className="d-flex">
                                            <p className="mb-0 mr-30">Database ID: {databaseInfo.database_id}</p>
                                            <p className="mb-0 mr-30">Database Token: {databaseInfo.database_token}</p>
                                            <p className="">Database Table ID: {e.table_id}</p>
                                        </div>
                                    </div>

                                    <p className="mb-0"><strong>Table Columns</strong>: {e.table_columns ? JSON.stringify(e.table_columns, null, 1).slice(1, -1) : 'undefined'}</p>

                                    <div className="ia-ce-show-apis-button mt-3" onClick={() => {
                                        let newArray = [...showAPI];
                                        newArray[i] = !newArray[i];
                                        setShowAPI(newArray);
                                    }}>
                                        <p className="ia-ce-white-text mb-0">{showAPI[i] ? 'Hide APIs' : 'Show APIs'}</p>
                                    </div>

                                    {showAPI[i]
                                        ? <div>
                                            <p className="mb-0 mt-4">Use this <strong>API POST</strong> request and body to insert a new data row into this database table:</p>
                                            <p className="mb-1">- The body is just an example. You'll need to replace the body 'database_column_values' key values with the values you would like to post.</p>
                                            <p className="ia-api-request">
                                                https://fingodb.com/api/database/table/row
                                                <br />
                                                {`{`} <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_id": {databaseInfo.database_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_token": "{databaseInfo.database_token}", <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"table_id": {e.table_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_column_values": {`{`} <br />
                                                {columns.map((el, index) => {
                                                    if (el[1] === 'string') {
                                                        return (
                                                            <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": "<span className="blue-text">your {el[1]}</span>", </p>
                                                        )
                                                    } else {
                                                        return (
                                                            <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": <span className="blue-text">your {el[1]}</span>, </p>
                                                        )
                                                    }
                                                })}
                                                &nbsp;&nbsp;&nbsp;&nbsp;{`}`} <br />
                                                {`}`}
                                            </p>

                                            <p className="mb-1 mt-4">Use this <strong>API GET</strong> request to get all the rows in this database table:</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/rows/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}</p>

                                            <p className="mb-0 mt-4">Use this <strong>API GET</strong> request to get all rows that match a column_name:column_value pair in this database table:</p>
                                            <p className="mb-0">- Replace 'your-column-name' with your column name and 'your-column-value' with your column value.</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/select/from/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}/where/<span className="blue-text">your-column-name</span>/equals/<span className="blue-text">your-column-value</span></p>

                                            <p className="mb-1 mt-4">Use this <strong>API PUT</strong> request and body to update a row in this database table:</p>
                                            <p className="mb-0">- The body is just an example. You'll need to replace the body 'database_column_values' key values with the values you would like to post.</p>
                                            <p className="mb-1"> - You can get the 'database_row_id' of the data row you want to update using an API GET request above.</p>
                                            <p className="ia-api-request">
                                                https://fingodb.com/api/database/table/row
                                                <br />
                                                {`{`} <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_id": {databaseInfo.database_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_token": "{databaseInfo.database_token}", <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"table_id": {e.table_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_row_id": <span className="blue-text">database_row_id</span>, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_column_values": {`{`} <br />
                                                {columns.map((el, index) => {
                                                    if (el[1] === 'string') {
                                                        return (
                                                            <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": "<span className="blue-text">your {el[1]}</span>", </p>
                                                        )
                                                    } else {
                                                        return (
                                                            <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": <span className="blue-text">your {el[1]}</span>, </p>
                                                        )
                                                    }
                                                })}
                                                &nbsp;&nbsp;&nbsp;&nbsp;{`}`} <br />
                                                {`}`}
                                            </p>

                                            <p className="mb-0 mt-4">Use this <strong>API DELETE</strong> request to delete a single row in this database table. (<strong>Be careful! This cannot be undone!</strong>):</p>
                                            <p className="mb-1"> - You can get the 'database_row_id' of the data row you want to delete using an API GET request above.</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/row/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}/<span className="blue-text">database_row_id</span></p>

                                            {/* need to finish the API request on the backend */}
                                            <p className="mb-0 mt-4">Use this <strong>API DELETE</strong> request to delete this entire database table. (<strong>Be careful! This cannot be undone!</strong>):</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}</p>
                                        </div>
                                        : null
                                    }
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}