import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Header.css';
import axios from "axios";

export default function Header({ AppState }) {
    const { host, userID, databaseInfo, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    const [databaseName, setDatabaseName] = useState('');

    useEffect(() => {
        setDatabaseName(databaseInfo.database_name);
    }, [databaseInfo])

    const handleGoToDash = () => {
        setDatabaseInfo({});
        navigate('/dashboard', { replace: true });
    }

    const handleUpdate = () => {
        axios
            .put(`${host}/api/database/name`, { userID, databaseID: databaseInfo.database_id, databaseName })
            .then(res => {
                setDatabaseInfo(res.data);
                alert('Update successful!');
            })
            .catch(err => console.log(err))
    }

    return (
        <header className='ia-header'>
            <div className='d-flex align-items-center' onClick={() => handleGoToDash()}>
                <img className='ia-logo' src={require('../../assets/logo.png')} alt='fingodb logo' />
            </div>
            <input 
                className="ia-header-input"
                type="text" 
                value={databaseName} 
                placeholder="untitled"
                onChange={(e) => setDatabaseName(e.target.value)} />
            <div className="ia-header-save-button" onClick={() => handleUpdate()}>
                <p className="ia-header-save-text mb-0">Update</p>
            </div>
        </header>
    )
}