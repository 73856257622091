import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import { Icon } from 'react-icons-kit';


import { toList } from 'react-icons-kit/entypo/toList';
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit';
import { gear } from 'react-icons-kit/fa/gear';
import { notepad_add } from 'react-icons-kit/ikons/notepad_add';

import './SideBar.css';

export default function SideBar({ AppState }) {
    let navigate = useNavigate();

    let location = useLocation();
    let path = location.pathname;

    return (
        <div className="ia-sidebar p-3 pt-4">
            <div className='ia-ps'>
                <div
                    className={path === '/in-database-create' ? 'ia-icon-chosen' : 'ia-icon'}
                    onClick={() => navigate('/in-database-create', { replace: true })}
                >
                    <Icon size={25} icon={toList} />
                    {/* create a new database table */}
                </div>
                <div
                    className={path === '/in-database-edit' ? 'ia-icon-chosen mt-5' : 'ia-icon mt-5'}
                    onClick={() => navigate('/in-database-edit', { replace: true })}
                >
                    <Icon size={28} icon={ic_mode_edit} />
                    {/* see current database rows */}
                </div>
                {/* <div
                    className={path === '/in-database-apis' ? 'ia-icon-chosen mt-5' : 'ia-icon mt-5'}
                    onClick={() => navigate('/in-database-apis', { replace: true })}
                >
                    <p className='ia-white-sidebar-text mb-0'>API</p>
                </div> */}
                <div
                    className={path === '/in-database-notes' ? 'ia-icon-chosen mt-5' : 'ia-icon mt-5'}
                    onClick={() => navigate('/in-database-notes', { replace: true })}
                >
                    <Icon size={25} icon={notepad_add} />
                    {/* create notes to plan out database */}
                </div>
                {/* <div
                    className={path === '/in-database-help' ? 'ia-icon-chosen mt-5' : 'ia-icon mt-5'}
                    onClick={() => navigate('/in-database-help', { replace: true })}
                >
                    <Icon size={24} icon={youtubePlay} /> */}
                    {/* create notes to plan out database */}
                {/* </div> */}
                <div
                    className={path === '/in-database-settings' ? 'ia-icon-chosen mt-5' : 'ia-icon mt-5'}
                    onClick={() => navigate('/in-database-settings', { replace: true })}
                >
                    <Icon size={27} icon={gear} />
                    {/* create notes to plan out database */}
                </div>
            </div>
           
        </div>
    )
}