import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './Edit.css';
import axios from "axios";

import Header from '../../../../Headers/InDatabase/Header';
import SideBar from "../Components/SideBar/SideBar";

export default function Edit({ AppState }) {
    const { host, userID, setUserID, databaseInfo, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    const [databaseTables, setDatabaseTables] = useState([]);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        if (getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));

            // get database
            axios
                .get(`${host}/api/database/${getCookie('user_id')}/${getCookie('database_id')}`)
                .then(res => {
                    // console.log("res.data: ", res.data);
                    setDatabaseInfo(res.data);
                    getAllDatabaseTables();
                })
                .catch(err => console.log(err));
        } else {
            navigate('/', { replace: true });
        }

        // console.log("userID, databaseInfo: ", userID, databaseInfo);
    }, []);

    const getAllDatabaseTables = () => {
        // get all databaseTables
        axios
            .get(`${host}/api/all/database/tables/${getCookie('user_id')}/${getCookie('database_id')}`)
            .then(res => setDatabaseTables(res.data))
            .catch(err => console.log(err))
    }

    const handleUpdateTable = (table_id, tName, tColumns) => {
        axios
            .put(`${host}/api/database/table`, { userID, databaseID: databaseInfo.database_id, databaseTableID: table_id, tableName: tName, tableColumns: JSON.parse(`{ ${tColumns} }`) })
            .then(() => {
                alert("Update successful!");
                getAllDatabaseTables();
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="ia-ce-page">
            <Header AppState={AppState} />
            <div className="ia-ce-body">
                <SideBar AppState={AppState} />
                <div className="ia-ce-body-right">
                    <h3 className="ia-ce-header-text-30">Update existing database tables:</h3>
                    <div className="d-flex flex-wrap pb-5">
                        {/* map all databaseTables */}
                        {databaseTables.map((e, i) => {
                            let tName;
                            let tColumns = [];

                            if(e.table_name) tName = e.table_name;
                            if(e.table_columns) tColumns = JSON.stringify(e.table_columns, null, 1).slice(1, -1);

                            return (
                                <div className="ia-ce-input-edit-cont" key={i}>
                                    <h3 className="ia-ce-header-text">{e.table_name ? e.table_name : 'undefined'}</h3>
                                    <input
                                        className="ia-ce-input mt-3"
                                        type="text"
                                        defaultValue={tName}
                                        placeholder={"Edit table name"}
                                        onChange={(text) => tName = text.target.value} />
                                    <input
                                        className="ia-ce-input mt-3"
                                        type="text"
                                        defaultValue={tColumns}
                                        placeholder="Edit columns"
                                        onChange={(text) => tColumns = text.target.value} />
                                    <div className="ia-ce-edit-button mt-5" onClick={() => handleUpdateTable(e.table_id, tName, tColumns)}>
                                        <p className="ia-ce-white-text mb-0">Update Table</p>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}