import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import '../Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../../../Headers/Home/Header';

export default function SignUp({ AppState }) {
    const { host, setUserID } = AppState;
    let navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));
            navigate('/dashboard', { replace: true });
        }
    }, []);

    const handleSubmit = () => {
        if(!name || !email || !password) {
            return alert('Provide a name, email, and password before clicking "Sign Up".')
        }
        if(!email.includes('@') || !email.includes('.')) {
            return alert('Your email address does not exist. Please, try again.');
        }
        if(password.length < 6) {
            return alert('Your password must be at least 6 characters long. Please, try again.');
        }

        axios
            .post(`${host}/api/auth/register`, { name, email, password })
            .then(res => {
                setUserID(res.data.user_id);
                document.cookie = `user_id = ${res.data.user_id}; domain=fingodb.com; max-age=31536000;`;
                document.cookie = `user_id = ${res.data.user_id}; max-age=31536000;`;
                document.cookie = `logged_in = true; domain=fingodb.com; max-age=31536000;`;
                document.cookie = `logged_in = true; max-age=31536000;`;
                navigate('/dashboard', { replace: true });
            })
            .catch(() => alert('That email is already in use. Try again with another email or go to the Login page to login.'))
    }

    return (
        <div className='page'>
            <Header />
            <>
                <div className='container-fluid '>
                    <div className='row align-items-center justify-content-center p-4 vh-90'>
                        <div className='top-of-page-cont'>
                            <div className="col-md-5 sign-up-cont-dark">
                                <h5 className='sign-up-text mb-3'>Create your first API database in under 5 minutes.</h5>
                                <input 
                                    className="sign-up-input"
                                    type="text" 
                                    value={name} 
                                    placeholder="Name"
                                    onChange={(e) => setName(e.target.value)} />
                                <input 
                                    className="sign-up-input mt-3"
                                    type="text" 
                                    value={email} 
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)} />

                                <input 
                                    className="sign-up-input mt-3"
                                    type="password" 
                                    value={password} 
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)} />

                                <div className="sign-up-button-blue mt-3" onClick={() => handleSubmit()}>
                                    <p className="sign-up-button-text mb-0">Sign Up Free</p>
                                </div>

                                <a className='sign-up-text mt-4' href="/#/login">Already have an account? Click here to log in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <footer className="mt-5 small-text">
                <p className="mb-0 pb-5">© 2022 Native Notify, INC | tj@nativenotify.com</p>
            </footer>
        </div>
    );
}
