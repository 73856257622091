import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom";

import Home from '../Pages/Home/Home';
import Login from '../Pages/Home/Login/Login';
import SignUp from '../Pages/Home/SignUp/SignUp';
import ForgotPassword from '../Pages/Home/ForgotPassword/ForgotPassword';

import Dashboard from "../Pages/Dashboard/Dashboard";
import UserSettings from "../Pages/Settings/Settings";

// in app pages
import Create from "../Pages/InDatabase/DatabasePages/Create/Create";
import Edit from "../Pages/InDatabase/DatabasePages/Edit/Edit";
import Search from "../Pages/InDatabase/DatabasePages/APIs/APIs";
import Notes from "../Pages/InDatabase/DatabasePages/Notes/Notes";
import Help from "../Pages/InDatabase/DatabasePages/Help/Help";
import Settings from "../Pages/InDatabase/DatabasePages/Settings/Settings";

export default function AppNavigation({ AppState }) {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Home AppState={AppState} />} />
                <Route path="/login" element={<Login AppState={AppState} />} />
                <Route path="/sign-up" element={<SignUp AppState={AppState} />} />
                <Route path="/forgot-password" element={<ForgotPassword AppState={AppState} />} />

                <Route path="/dashboard" element={<Dashboard AppState={AppState} />} />
                <Route path="/settings" element={<UserSettings AppState={AppState} />} />

                <Route path="/in-database-create" element={<Create AppState={AppState} />} />
                <Route path="/in-database-edit" element={<Edit AppState={AppState} />} />
                <Route path="/in-database-apis" element={<Search AppState={AppState} />} />
                <Route path="/in-database-notes" element={<Notes AppState={AppState} />} />
                <Route path="/in-database-help" element={<Help AppState={AppState} />} />
                <Route path="/in-database-settings" element={<Settings AppState={AppState} />} />

                <Route path="*" element={<Home AppState={AppState} />} />
            </Routes>
        </HashRouter>
    )
}