import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import '../Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../../../Headers/Home/Header';

export default function ForgotPassword({ AppState }) {
    const { host, setUserID } = AppState;
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [temporaryUserId, setTemporaryUserId] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [confirmResetCode, setConfirmResetCode] = useState('');
    const [showFirstScreen, setShowFirstScreen] = useState(true);
    const [showSecondScreen, setShowSecondScreen] = useState(false);
    const [showThirdScreen, setShowThirdScreen] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        document.title = 'Reset Password';
    }, [])

    const sendResetCode = () => {
        if(!email) {
            return alert('Provide an email before clicking "Submit".')
        }
        if(!email.includes('@') || !email.includes('.')) {
            return alert('Your email address is not a real email. Please, try again.');
        }

        axios
            .post(`${host}/api/resetCode`, { email })
            .then(res => {
                console.log("res.data: ", res.data);
                if(res.data === "Sorry, that email is not associated with a Native Notify account. Please try again.") {
                    return alert("Sorry, that email is not associated with a Native Notify account. Please try again.")
                }
                setResetCode(res.data.code);
                setTemporaryUserId(res.data.user_id);
                setShowFirstScreen(false);
                setShowSecondScreen(true);
                setShowThirdScreen(false);
            })
            .catch(err => console.log(err))
    }

    const checkResetCode = () => {
        if(resetCode == confirmResetCode) {
            setShowFirstScreen(false);
            setShowSecondScreen(false);
            setShowThirdScreen(true);
        } else {
            alert("Sorry, the reset code you typed does not match our records. Please, try again.")
        }
    }

    const checkPassword = () => {
        if(password === confirmPassword) {
            setUserID(temporaryUserId);
            axios
                .post(`${host}/api/resetPassword`, { email, password })
                .then(res => {
                    console.log(res.data);
                    alert("Your password was successfully reset.")
                    navigate('/login', { replace: true });
                })
        } else {
            alert("Sorry, the password and confirm password do not match. Please, try again.")
        }
    }

    return (
        <div className='page'>
            <Header />
            <>
                <div className='container-fluid '>
                    <div className='row align-items-center justify-content-center p-4 vh-90'>
                        <div className='top-of-page-cont'>
                            {showFirstScreen
                                ? <div className="col-md-5 sign-up-cont-dark">
                                    <h1 className='sign-up-text mb-1'>Reset Password</h1>
                                    <p className='sign-up-text mb-4'>What email is associated with your account?</p>
                                    <input 
                                        className="sign-up-input"
                                        type="text" 
                                        value={email} 
                                        placeholder="Email"
                                        onChange={(e) => setEmail(e.target.value)} />

                                    <div className="sign-up-button-blue mt-3" onClick={() => sendResetCode()}>
                                        <p className="sign-up-button-text mb-0">Submit</p>
                                    </div>
                                  </div>
                                : null
                            }
                            {showSecondScreen
                                ? <div className="col-md-5 sign-up-cont-dark">
                                    <h1 className='sign-up-text mb-1'>Check your email</h1>
                                    <p className='sign-up-text mb-4'>We sent a password reset code to your email. Please, type that code below:</p>
                                    <input 
                                        className="sign-up-input"
                                        type="text" 
                                        value={confirmResetCode} 
                                        placeholder="Reset code"
                                        onChange={(e) => setConfirmResetCode(e.target.value)} />

                                    <div className="sign-up-button-blue mt-3" onClick={() => checkResetCode()}>
                                        <p className="sign-up-button-text mb-0">Submit</p>
                                    </div>
                                  </div>
                                : null
                            }
                             {showThirdScreen
                                ? <div className="col-md-5 sign-up-cont-dark">
                                    <h1 className='sign-up-text mb-1'>Reset Password</h1>
                                    <input 
                                        className="sign-up-input"
                                        type="password" 
                                        value={password} 
                                        placeholder="New Password"
                                        onChange={(e) => setPassword(e.target.value)} />
                                    <input 
                                        className="sign-up-input mt-3"
                                        type="password" 
                                        value={confirmPassword} 
                                        placeholder="Confirm New Password"
                                        onChange={(e) => setConfirmPassword(e.target.value)} />

                                    <div className="sign-up-button-blue mt-3" onClick={() => checkPassword()}>
                                        <p className="sign-up-button-text mb-0">Reset Password</p>
                                    </div>
                                  </div>
                                : null
                            }
                            

                        </div>
                    </div>
                </div>
            </>
            <footer className="mt-5 small-text">
                <p className="mb-0 pb-5">© 2022 Native Notify, INC | tj@nativenotify.com</p>
            </footer>
        </div>
    );
}
