import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Settings.css';
import axios from 'axios';

import Header from '../../Headers/Dashboard/Header';

import StripeCheckout from 'react-stripe-checkout';

export default function Settings({ AppState }) {
    const { host, setUserID, userInfo, setUserInfo } = AppState;
    let navigate = useNavigate();

    const [oldEmail, setOldEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));
            axios
                .get(`${host}/api/user/${getCookie('user_id')}`)
                .then(res => {
                    console.log(res.data, userInfo);
                    setUserInfo(res.data);
                    setOldEmail(res.data.email);
                })
                .catch(err => console.log(err))
        } else {
            return navigate('/', { replace: true });
        }
    }, []);

    const onToken = (token) => {
        console.log(token)
        token.card = void 0;

        axios
          .post(`${host}/api/stripe/update/card`, { token, email: userInfo.email})
          .then(res => {
            //   console.log(res);
            alert("You have successfully updated your credit card info!");
          })
          .catch()
    }

    const handleDeleteUser = () => {
        // console.log(getCookie('user_id'));
    
        if(window.confirm("Are you sure you want to cancel your membership?")) {
            axios
                .delete(`${host}/api/delete/user/${getCookie('user_id')}`)
                .then(() => {
                    // console.log(res);
                    alert("You have successfully deleted your account. We will save your account data in case you want to become a FingoDB member again. Just come back to FingoDB.com, login, and all of your data will be waiting for you.");
                    setUserID(0);
                    var d = new Date();
                    d.setDate(d.getDate() - 1);
                    document.cookie = `user_id = 0; domain=fingodb.com; expires=${d};`;
                    document.cookie = `user_id = 0; expires=${d};`;
                    document.cookie = `logged_in = false; domain=fingodb.com; expires=${d};`;
                    document.cookie = `logged_in = false; expires=${d};`;
                    document.cookie = `database_id = 0; domain=fingodb.com; expires=${d};`;
                    document.cookie = `database_id = 0; expires=${d};`;
                    navigate('/', { replace: true });
                })
                .catch(err => console.log(err))
        } 
    }

    const changeEmail = () => {
        if(!newEmail.includes('@') || !newEmail.includes('.')) {
            return alert('Type a real email address before changing your email address.');
        }

        if(window.confirm("Are you sure you want to change your email?")) {
            axios
                .put(`${host}/api/update/email`, { oldEmail, newEmail })
                .then(res => {
                    setOldEmail(res.data[0].email);
                    setNewEmail('');
                })
                .catch(() => {
                    alert("The new email is already in use with another account. Please try to update your email with a different email not currently in use with another FingoDB account.");
                    setNewEmail('');
                })
        }
        
    }

    return (
        <div className="settings-page">
            <Header AppState={AppState} />

            <div className="settings-body">
                <p>Current email: {oldEmail}</p>
                <input 
                    className="sign-up-input"
                    type="text" 
                    onChange={(e) => setNewEmail(e.target.value)}
                    placeholder={"Type new email here..."}
                    placeholderTextColor={"#6e6d6c"}
                    value={newEmail}
                />
                <button className="settings-red-button mt-3" onClick={() => changeEmail(newEmail)}>
                    <p className="settings-white-text">Change your email</p>
                </button>

                <button className="settings-blue-button mt-3" onClick={() => navigate('/forgot-password', { replace: false })}>
                    <p className="settings-white-text">Change your password</p>
                </button>

                {userInfo.paid_sub
                    ? <div>
                        <StripeCheckout
                            image={require('../../assets/logo.png')}
                            name="Update Card"
                            panelLabel="Update Card"
                            email={userInfo.email}
                            
                            currency='USD'
                            locale='auto'
                            stripeKey='pk_live_9o7xarrQQSR8yfoIwh5r2uIk00yFNr81db'
                            token={onToken}
                        >
                            <p className="settings-go-pro-button mb-5">Change your payment card information</p>
                        </StripeCheckout>

                        <p><strong>Account Data Row Count:</strong> {userInfo.data_row_count}</p>

                        {!userInfo.custom_pricing
                            ? <p>
                                <strong>Your monthly FingoDB subscription fee:</strong> $
                                {userInfo.data_row_count / 3 < 1 
                                    ? '0' 
                                    : `${(Math.floor(userInfo.data_row_count / 1000000) * 25) + 25}`
                                }/month
                              </p>
                            : null
                        }

                        <div className='settings-danger-cont'>
                            <p className='settings-red-text'>Danger Zone!</p>
                            <button className="settings-red-button mt-0 mb-2"onClick={() => handleDeleteUser()}>
                                <p className="settings-white-text">Cancel Membership</p>
                            </button>
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
        
    )
}

const styles = {
    page: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        backgroundColor: 'rgb(250, 250, 250)',
    },
    body: {
        width: '90%',
        // maxWidth: 700,
        alignItems: 'flex-start'
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
        color: '#fff',
    },
    button: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 60
    },
    button2: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 20
    },
    redButton: {
        backgroundColor: '#ff0000',
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 20
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    contDanger: {
      width: "90%",
      maxWidth: 500,
      marginTop: 100,
      marginBottom: 50,
      padding: 30,
      borderRadius: 3,
      backgroundColor: "#fff",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    text: {
        fontSize: 18,
        fontFamily: "Arial",
        marginTop: 60,
        marginBottom: 10
    },
    text2: {
        fontSize: 18,
        fontFamily: "Arial",
        marginTop: 10
    },
    textBold:{
        fontWeight: 700,
    },
    textRed: {
      fontSize: 20,
      fontFamily: "Arial",
      fontWeight: 700,
      color: "#ff0000",
      marginBottom: 50,
      textAlign: "center",
    },
    inputLarge: {
        width: 300,
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        // marginBottom: 15
    }
}