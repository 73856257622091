import './Header.css';

export default function Header() {
    return (
        <header className='header'>
            <a className='d-flex align-items-center' href='/'>
                <img className='logo' src={require('../../assets/logo.png')} alt='fingodb logo' />
                <h1 className='logo-text mb-0'>FingoDB</h1>
            </a>
            <div className="d-flex">
                <a className="sign-up-button-sm-clear" href='/#/login'>
                    <p className="sign-up-button-text-dark mb-0">Login</p>
                </a>
                <a className="sign-up-button-sm" href='/#/sign-up'>
                    <p className="sign-up-button-text mb-0">Sign Up Free</p>
                </a>
            </div>
        </header>
    )
}