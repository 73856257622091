import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import '../Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../../../Headers/Home/Header';

export default function Login({ AppState }) {
    const { host, setUserID } = AppState;
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));
            navigate('/dashboard', { replace: true });
        }
    }, []);

    const handleSubmit = async () => {
        if(!email || !password) {
            return alert('Provide an email and password before clicking "Login".')
        }
        if(!email.includes('@') || !email.includes('.')) {
            return alert('Your email address does not exist. Please, try again.');
        }
        if(password.length < 6) {
            return alert('Your password must be at least 6 characters long. Please, try again.');
        }

        await axios
            .post(`${host}/api/auth/login`, { email, password })
            .then(res => {
                setUserID(res.data.user_id);
                document.cookie = `user_id = ${res.data.user_id}; domain=fingodb.com; max-age=31536000;`;
                document.cookie = `user_id = ${res.data.user_id}; max-age=31536000;`;
                document.cookie = `logged_in = true; domain=fingodb.com; max-age=31536000;`;
                document.cookie = `logged_in = true; max-age=31536000;`;
                navigate('/dashboard', { replace: true });
            })
            .catch((err) => {
                console.log("err: ", err.response.data);
                if(err.response.data === "Incorrect password") {
                    setPassword('');
                    return alert('Password incorrect. Try again.')
                }
                setEmail('');
                setPassword('');
                alert('That email was not found. Try again with another email or sign up.');
            })

    }

    return (
        <div className='page'>
            <Header />
            <>
                <div className='container-fluid '>
                    <div className='row align-items-center justify-content-center p-4 vh-90'>
                        <div className='top-of-page-cont'>
                            <div className="col-md-5 sign-up-cont-dark">
                                <input 
                                    className="sign-up-input"
                                    type="text" 
                                    value={email} 
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)} />

                                <input 
                                    className="sign-up-input mt-3"
                                    type="password" 
                                    value={password} 
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)} />

                                <div className="sign-up-button-blue mt-3" onClick={() => handleSubmit()}>
                                    <p className="sign-up-button-text mb-0">Login</p>
                                </div>

                                <a className='sign-up-text mt-4' href="/#/forgot-password">Forgot Password?</a>

                                <div className="sign-up-button-create mt-5" onClick={() => navigate('/sign-up', { replace: false })}>
                                    <p className="sign-up-button-text-dark mb-0">Create New Account</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <footer className="mt-5 small-text">
                <p className="mb-0 pb-5">© 2022 Native Notify, INC | tj@nativenotify.com</p>
            </footer>
        </div>
    );
}
