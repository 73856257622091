import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Settings.css';
import axios from "axios";

import Header from '../../../../Headers/InDatabase/Header';
import SideBar from "../Components/SideBar/SideBar";

export default function Help({ AppState }) {
    const { host, userID, setUserID, databaseInfo, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if (getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));

            axios
                .get(`${host}/api/database/${getCookie('user_id')}/${getCookie('database_id')}`, { userID })
                .then(res => {
                    // console.log("res.data: ", res.data);
                    setDatabaseInfo(res.data);
                })
                .catch(err => console.log(err));
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    const deleteDatabase = () => {
        let confirm = window.confirm("Do you really want to take this database's life? It could have a family. Are you sure you want to kill this database?");

        if (confirm) {
            axios
                .delete(`${host}/api/database/${databaseInfo.database_id}/${databaseInfo.database_token}/${userID}`)
                .then(() => navigate('/', { replace: true }))
                .catch(err => console.log(err))
        }
    }

    return (
        <div className="ia-help-page">
            <Header AppState={AppState} />
            <div className="ia-help-body">
                <SideBar AppState={AppState} />
                <div className="ia-ce-body-right">
                    <h3 className="ia-ce-header-text-30 ia-red-text">Danger Zone!</h3>
                    <p className="ia-ce-header-text-30">If you click the button below, this database's life will end! And sadly, there's no such thing as a database resurrection from the dead. When a database dies, it's dead forever.</p>
                    <p className="ia-ce-header-text-30">It's a sad day when a database dies. We here at FingoDB hold a funeral each time it happens. It takes a long time and a lot of money to have a funeral for a database.</p>
                    <p className="ia-ce-header-text-30 mb-5">Please, make sure you actually want to delete your database before clicking the button below. Think long and hard...</p>

                    <div className="ia-ce-header-text-30 dash-create-button" onClick={() => deleteDatabase()}>
                        <p className="dash-white-text mb-0">Delete Database</p>
                    </div>
                </div>
            </div>
        </div>
    )
}