import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Help.css';
import axios from "axios";

import Header from '../../../../Headers/InDatabase/Header';
import SideBar from "../Components/SideBar/SideBar";

export default function Help({ AppState }) {
    const { host, userID, setUserID, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    const [name, setName] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if (getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));

            axios
                .get(`${host}/api/database/${getCookie('user_id')}/${getCookie('database_id')}`, { userID })
                .then(res => {
                    console.log("res.data: ", res.data);
                    setDatabaseInfo(res.data);
                })
                .catch(err => console.log(err));
        } else {
            navigate('/', { replace: true });
        }
    }, []);

    return (
        <div className="ia-help-page">
            <Header AppState={AppState} />
            <div className="ia-help-body">
                <SideBar AppState={AppState} />
                <div className="ia-ce-body-right">
                    <h3 className="ia-ce-header-text-30">Video Tutorials:</h3>
                    <p className="">Still to do:</p>
                    <p className="">- Finish last 3 API calls</p>
                    <p className="">- start marketing FingoDB.com.</p>
                    <p className="">- create payment system based on user activity</p>
                </div>
            </div>
        </div>
    )
}