import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './Create.css';
import axios from "axios";

import Header from '../../../../Headers/InDatabase/Header';
import SideBar from "../Components/SideBar/SideBar";

export default function Create({ AppState }) {
    const { host, userID, setUserID, databaseInfo, setDatabaseInfo } = AppState;
    let navigate = useNavigate();

    const [tableName, setTableName] = useState('');
    const [columns, setColumns] = useState('');
    const [databaseTables, setDatabaseTables] = useState([]);
    const [showAPI, setShowAPI] = useState([]);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        if (getCookie('logged_in') == 'true') {
            setUserID(getCookie('user_id'));

            // get app
            axios
                .get(`${host}/api/database/${getCookie('user_id')}/${getCookie('database_id')}`)
                .then(res => {
                    // console.log("res.data: ", res.data);
                    setDatabaseInfo(res.data);
                    getAllDatabaseTables();
                })
                .catch(err => console.log(err));
        } else {
            navigate('/', { replace: true });
        }

        // console.log("userID, databaseInfo: ", userID, databaseInfo);
    }, []);

    const getAllDatabaseTables = () => {
        // get all databases
        axios
            .get(`${host}/api/all/database/tables/${getCookie('user_id')}/${getCookie('database_id')}`)
            .then(res => {
                setDatabaseTables(res.data);
                res.data.forEach(() => setShowAPI(element => [...element, true]));
            })
            .catch(err => console.log(err))
    }

    const handleCreateTable = () => {
        axios
            .post(`${host}/api/database/table`, { userID, databaseID: databaseInfo.database_id, tableName: tableName, tableColumns: JSON.parse(`{ ${columns} }`) })
            .then(() => {
                alert("Database table successfully created!");
                setTableName('');
                setColumns('');
                getAllDatabaseTables();
            })
            .catch(err => console.log(err))
    }

    const handleUpdateTable = (table_id, tName, tColumns) => {
        axios
            .put(`${host}/api/database/table`, { userID, databaseID: databaseInfo.database_id, databaseTableID: table_id, tableName: tName, tableColumns: JSON.parse(`{ ${tColumns} }`) })
            .then(() => {
                alert("Update successful!");
                getAllDatabaseTables();
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="ia-ce-page">
            <Header AppState={AppState} />
            <div className="ia-ce-body">
                <SideBar AppState={AppState} />
                <div className="ia-ce-body-right mb-5 pb-5">
                    <div className="ie-ce-top-section">
                        <div className="ia-ce-input-cont">
                            <h3 className="ia-ce-header-text">Create a new database table:</h3>
                            <p className="ia-ce-text mb-0 mt-3">Table name:</p>
                            <input
                                className="ia-ce-create-input"
                                type="text"
                                value={tableName}
                                placeholder="Type database table name here..."
                                onChange={(text) => setTableName(text.target.value)} />

                            <p className="ia-ce-text mb-0 mt-3">Table columns:</p>
                            <input
                                className="ia-ce-create-input"
                                type="text"
                                value={columns}
                                placeholder='Type database table columns here in this format: "column_name": "column_data_type", "column_name": "column_data_type"'
                                onChange={(text) => setColumns(text.target.value)} />
                            <div className="ia-ce-create-button mt-5" onClick={() => handleCreateTable()}>
                                <p className="ia-ce-white-text mb-0">Create Table</p>
                            </div>
                        </div>
                        {/* map all databases */}
                        {databaseTables.map((e, i) => {
                            let tName = e.table_name;
                            
                            // console.log(e.table_columns)

                            let tColumns;
                            let columns = [];

                            if(e.table_columns) {
                                tColumns = JSON.stringify(e.table_columns, null, 1).slice(1, -1);
                                columns = Object.entries(e.table_columns);
                            }

                            return (
                                <div className="ia-ce-input-edit-cont" key={i}>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <h3 className="ia-ce-header-text mr-30">{e.table_name ? e.table_name : 'undefined'}</h3>
                                        <div className="d-flex">
                                            <p className="mb-0 mr-30">Database ID: {databaseInfo.database_id}</p>
                                            <p className="mb-0 mr-30">Database Token: {databaseInfo.database_token}</p>
                                            <p className="">Database Table ID: {e.table_id}</p>
                                        </div>
                                    </div>

                                    <p className="mb-0"><strong>Table Columns</strong>: {e.table_columns ? JSON.stringify(e.table_columns, null, 1).slice(1, -1) : 'undefined'} </p>

                                    <div className="ia-ce-show-apis-button mt-3" onClick={() => {
                                        let newArray = [...showAPI];
                                        newArray[i] = !newArray[i];
                                        setShowAPI(newArray);
                                    }}>
                                        <p className="ia-ce-white-text mb-0">{showAPI[i] ? 'Hide APIs' : 'Show APIs'}</p>
                                    </div>

                                    {showAPI[i]
                                        ? <div>
                                            <p className="mb-0 mt-4">Use this <strong>API POST</strong> request and body to insert a new data row into this database table:</p>
                                            <p className="mb-1">- The body is just an example. You'll need to replace the body 'row_columns_values' key values with the values you would like to post.</p>
                                            <p className="ia-api-request">
                                                https://fingodb.com/api/database/table/row
                                                <br />
                                                {`{`} <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_id": {databaseInfo.database_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_token": "{databaseInfo.database_token}", <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"table_id": {e.table_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"row_columns_values": {`{`} <br />
                                                {columns.map((el, index) => {
                                                    if(index === columns.length - 1) {
                                                        if (el[1] === 'string') return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": "<span className="blue-text">your {el[1]}</span>"</p>

                                                        return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": <span className="blue-text">your {el[1]}</span></p>
                                                    } 

                                                    if (el[1] === 'string') return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": "<span className="blue-text">your {el[1]}</span>", </p>

                                                    return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": <span className="blue-text">your {el[1]}</span>, </p>
                                                })}
                                                &nbsp;&nbsp;&nbsp;&nbsp;{`}`} <br />
                                                {`}`}
                                            </p>

                                            <p className="mb-1 mt-4">Use this <strong>API GET</strong> request to get all the rows in this database table:</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/rows/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}</p>

                                            <p className="mb-0 mt-4">Use this <strong>API GET</strong> request to get all rows that match a column_name:column_value pair in this database table:</p>
                                            <p className="mb-0">- Replace 'your-column-name' with your column name and 'your-column-value' with your column value.</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/select/from/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}/where/<span className="blue-text">your-column-name</span>/equals/<span className="blue-text">your-column-value</span></p>

                                            <p className="mb-1 mt-4">Use this <strong>API PUT</strong> request and body to update a row in this database table:</p>
                                            <p className="mb-0">- The body is just an example. You'll need to replace the body 'row_columns_values' key values with the values you would like to post.</p>
                                            <p className="mb-1"> - You can get the 'row_id' of the data row you want to update using an API GET request above.</p>
                                            <p className="ia-api-request">
                                                https://fingodb.com/api/database/table/row
                                                <br />
                                                {`{`} <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_id": {databaseInfo.database_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"database_token": "{databaseInfo.database_token}", <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"table_id": {e.table_id}, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"row_id": <span className="blue-text">row_id</span>, <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp;"row_columns_values": {`{`} <br />
                                                {columns.map((el, index) => {
                                                    if(index === columns.length - 1) {
                                                        if (el[1] === 'string') return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": "<span className="blue-text">your {el[1]}</span>"</p>

                                                        return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": <span className="blue-text">your {el[1]}</span></p>
                                                    } 

                                                    if (el[1] === 'string') return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": "<span className="blue-text">your {el[1]}</span>", </p>

                                                    return <p className="white-text mb-0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{el[0]}": <span className="blue-text">your {el[1]}</span>, </p>
                                                })}
                                                &nbsp;&nbsp;&nbsp;&nbsp;{`}`} <br />
                                                {`}`}
                                            </p>

                                            <p className="mb-0 mt-4">Use this <strong>API DELETE</strong> request to delete a single row in this database table. (<strong>Be careful! This cannot be undone!</strong>):</p>
                                            <p className="mb-1"> - You can get the 'row_id' of the data row you want to delete using an API GET request above.</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/row/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}/<span className="blue-text">row_id</span></p>

                                            {/* need to finish the API request on the backend */}
                                            <p className="mb-0 mt-4">Use this <strong>API DELETE</strong> request to delete this entire database table. (<strong>Be careful! This cannot be undone!</strong>):</p>
                                            <p className="ia-api-request">https://fingodb.com/api/database/table/{databaseInfo.database_id}/{databaseInfo.database_token}/{e.table_id}</p>
                                        </div>
                                        : null
                                    }
                                    <p className="ia-ce-header-text mb-0 mt-5">Update Table Name/Columns:</p>
                                    <input
                                        className="ia-ce-input mt-3"
                                        type="text"
                                        defaultValue={tName}
                                        placeholder={"Edit table name"}
                                        onChange={(text) => tName = text.target.value} />
                                    <input
                                        className="ia-ce-input mt-3"
                                        type="text"
                                        defaultValue={tColumns}
                                        placeholder="Edit columns"
                                        onChange={(text) => tColumns = text.target.value} />
                                    <div className="ia-ce-edit-button mt-5" onClick={() => handleUpdateTable(e.table_id, tName, tColumns)}>
                                        <p className="ia-ce-white-text mb-0">Update Table</p>
                                    </div>
                                </div>
                            )
                        })}
                        <h3 className="ia-ce-header-text-30 w-100">Watch this video to learn how to create a new database table:</h3>
                        <div className="ie-ce-iframe-cont">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/MlrVMleqSK0?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen" className="ie-ce-iframe"></iframe>
                        </div>
                        <div className="ie-ce-instructions-cont">
                            <h5 className="mb-4">Notes on how to create data columns:</h5>
                            <p className="mb-0"><strong>There are 3 data types:</strong></p>
                            <ol>
                                <li>string</li>
                                <li>number</li>
                                <li>boolean</li>
                            </ol>
                            <p className=""><strong>Type your database columns in "key":"value" pairs separated by commas. The "key" is the column name. The "value" is the data type. Here is an example:</strong></p>
                            <p className="ia-ce-create-input">"user_name":"string", "user_age":"number", "user_is_human":"boolean"  </p>
                            <p className=""><strong>Make sure to put quotation marks (" ") around the key name and the value name of each "key":"value" pair.</strong></p>
                            <p className="mb-0"><strong>What about dates?</strong></p>
                            <p className="mb-0">- We automatically add a date timestamp to each data row posted. You will be able to get the date and time the row was posted when you get the data row using our API GET request.</p>
                            <p className="">- If you would like to create your own date column, you could create a "date" column with a "string" data type and create the date on the front end and save it as a string.</p>

                            <p className="mb-0"><strong>What about serial primary keys?</strong></p>
                            <p className="mb-0">- We automatically add a serial primary key to each data row posted. You will be able to get the row serial primary key when you get the data row using our API GET request.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}